import { QueryClient, QueryKey } from '@tanstack/react-query';
import { isEqual } from 'lodash';

export type RemoveQueriesExceptExcludedArgs = {
  removeQueriesKeys?: QueryKey;
  excludedQueryKey?: QueryKey;
  queryClient: QueryClient;
};

/**
 *
 * @param removeQueriesKeys - queryKey that should be removed
 * @param excludedQueryKey - queryKey that should not be removed
 * @returns
 */
export const removeQueriesExceptExcluded = ({
  removeQueriesKeys,
  excludedQueryKey,
  queryClient,
}: RemoveQueriesExceptExcludedArgs) => {
  if (!removeQueriesKeys) {
    return;
  }

  queryClient.removeQueries({
    queryKey: removeQueriesKeys,
    predicate: ({ options: queryOptions }) => {
      if (!excludedQueryKey) {
        return true;
      }
      if (!queryOptions?.queryKey) {
        return false;
      }
      return !isEqual(queryOptions.queryKey, excludedQueryKey);
    },
  });
};
