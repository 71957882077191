import { array, object, string } from 'yup';

export const metaValidationSchema = array()
  .of(
    object().shape({
      key: string().trim().required(`Key is required!`),
      value: string().trim().required(`Value is required!`),
    })
  )
  .test(
    'unique',
    'Meta keys should be unique',
    (meta?: { key: string; value: string }[]) => !!meta && meta.length === new Set(meta.map(({ key }) => key)).size
  );
